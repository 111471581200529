
import { Injectable, Compiler } from '@angular/core';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { RestService } from '../core/services/rest.service';
import { DataService } from '../core/services/data.service';
import { IRestResponse } from '../core/interface/response.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService,
    private compiler: Compiler,
    private dataService: DataService,
    private router: Router) { }

  public forceLogout() {
    this.compiler.clearCache();
    // this.dataService.clearOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  public login(data: any): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogin(environment.baseUrl + `/oauth/token`, data).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public logout(): Observable<IRestResponse<any>> {
    return this.restService
      .oauthLogout(environment.baseUrl + `/oauth/logout`, false).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  get isLoggedIn() {
    if (localStorage.getItem('user_type') !== null) {
      return true;
    }
    return false;
  }
}
